<template>
  <div
    class="flex items-center relative text-etBlack-100 py-4 border-b border-etGray-primary"
  >
    <img
      class="w-[50px] h-full object-cover rounded"
      :src="require(`../../assets/icons/payment-logos/${brand}.png`)"
      :alt="brand"
    />
    <div
      class="flex gap-3 justify-center pl-4"
      :class="{
        '!justify-between flex-wrap items-center w-full': setDefault
      }"
    >
      <div class="flex flex-col" :class="{ 'items-center': !isCard }">
        <TextSmall v-if="isCard" class="font-semibold uppercase">{{
          `${brand} •••• ${method.attributes.card.last4}`
        }}</TextSmall>
        <TextSmall v-else class="font-semibold uppercase">{{
          `Sepa •••• ${method.attributes.iban.last4}(${method.attributes.iban.country})`
        }}</TextSmall>
        <div class="flex relative">
          <template v-if="isCard">
            <TextSmall class="block text-etBlack-80 font-light pr-3">
              {{ $t('shared.label.expires') }}
            </TextSmall>
            <TextSmall class="block text-etBlack-100">{{
              `${method.attributes.card.exp_month}/${method.attributes.card.exp_year}`
            }}</TextSmall>
          </template>
        </div>
      </div>
      <div v-if="method.attributes.is_default">
        <TextSmall
          class="uppercase text-etBlue-primary bg-etBlue-10 font-medium rounded py-0 px-1"
          >{{ $t('shared.label.default') }}
        </TextSmall>
      </div>
      <div v-if="!method.attributes.is_default && !disabled && setDefault">
        <TextSmall
          class="border border-etBlue-primary rounded py-0.5 px-1 cursor-pointer"
          @click="changeDefault(method.id)"
          >{{ $t('shared.label.set_as_default') }}
        </TextSmall>
      </div>
      <ButtonEditCard
        class="top-1/2 right-0 -translate-y-1/2"
        :class="{ hidden: hideActions }"
      >
        <template #option>
          <TextSmall
            v-if="!method.attributes.is_default"
            @click="changeDefault(method.id)"
            >{{ $t('shared.label.set_as_default') }}</TextSmall
          >
          <TextSmall
            class="!text-etRed-100"
            @click="onRemoveMethod(method.id)"
            >{{ $t('shared.button.delete') }}</TextSmall
          >
        </template>
      </ButtonEditCard>
    </div>
  </div>
</template>
<script setup>
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TextSmall from '@/components/shared/font/text/TextSmall'
import i18n from '@/i18n'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const props = defineProps({
  method: Object,
  setDefault: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  hideActions: Boolean
})

const emit = defineEmits(['setLoader'])

const brand = computed(() => {
  if (props.method.attributes.card) return props.method.attributes.card.brand
  return 'brand'
})
const isCard = computed(() => {
  if (props.method.attributes.card) return true
  return false
})

const updateDefaultBilling = (payload) =>
  store.dispatch('payment/updateDefaultBilling', payload)
const deleteBillingMethod = (payload) =>
  store.dispatch('payment/deleteBillingMethod', payload)

onMounted(async () => {
  emit('setLoader', false)
})
async function onRemoveMethod(id) {
  emit('setLoader', true)
  try {
    await deleteBillingMethod({ id })
  } finally {
    emit('setLoader', false)
  }
}
async function changeDefault(id) {
  emit('setLoader', true)
  try {
    await updateDefaultBilling({ billingMethodId: id })
  } finally {
    emit('setLoader', false)
  }
}
</script>
