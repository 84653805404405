<template>
  <div>
    <div
      v-if="baseRoute === 'clientSettings'"
      class="bg-white border-r border-b border-etBlack-30 flex flex-wrap justify-between items-center py-2 px-2.5 pr-6 h-auto desktop:h-[60px] desktop:absolute desktop:top-0 desktop:left-[60px] desktop:z-[3] desktop:w-[calc(100%-60px)]"
    >
      <div
        class="hidden items-center cursor-pointer desktop:flex"
        @click="router.push({ name: 'clientOverview' })"
      >
        <font-awesome-icon
          class="p-2 mr-0.5 rounded [&>path]:fill-[#767c98] hover:bg-[#f3f6fa]"
          icon="fa-solid fa-arrow-left"
        />
        <TitleRegular :title="routeTitle" />
      </div>
    </div>
    <div
      v-if="isSubnavVisible"
      class="grid relative grid-rows-[65px_1fr] p-4 pb-7 h-full overflow-y-auto border-r border-etGray-primary scrollbarHide"
      :class="{ 'subnav--active': isSubnavVisible }"
    >
      <h4
        v-if="
          baseRoute != 'clientSettings' && baseRoute != 'contentGenerationV2'
        "
        class="flex text-etBlack-100 text-lg font-bold capitalize row-[1_/_2]"
      >
        {{ routeTitle }}
      </h4>
      <div
        v-if="baseRoute === 'clientOverview'"
        class="flex flex-col row-[2_/_2]"
      >
        <router-link
          v-for="link in routes.workspace"
          :key="link.title"
          :data-cy="link.route"
          class="!text-etBlack-80 py-2 px-2.5 rounded break-words no-underline hover:!text-etBlue-primary [&.router-link-active]:!text-etBlue-primary [&.router-link-active]:bg-[#f3f6fa]"
          :to="{ name: link.route }"
        >
          <span>{{ $t(link.title) }}</span>
        </router-link>
      </div>
      <div
        v-if="baseRoute === 'contentGenerationV2'"
        class="flex flex-col row-[1]"
      >
        <router-link
          v-for="link in routes.caas"
          :key="link.title"
          class="!text-etBlack-80 py-2 px-2.5 rounded break-words no-underline hover:!text-etBlue-primary [&.router-link-active]:!text-etBlue-primary [&.router-link-active]:bg-[#f3f6fa]"
          :to="{ name: link.route }"
        >
          <span>{{ $t(link.title) }}</span>
        </router-link>
      </div>
      <div
        v-if="
          baseRoute === 'languageAssets' || baseRoute === 'importLanguageAssets'
        "
        class="flex flex-col row-[2_/_2]"
      >
        <router-link
          v-for="link in routes.languageAssets"
          :key="link.title"
          class="!text-etBlack-80 py-2 px-2.5 rounded break-words no-underline hover:!text-etBlue-primary [&.router-link-active]:!text-etBlue-primary [&.router-link-active]:bg-[#f3f6fa]"
          :to="{ name: link.route }"
        >
          <span>{{ $t(link.title) }}</span>
        </router-link>
      </div>
      <div
        v-if="baseRoute === 'clientSettings'"
        class="flex flex-col row-[2_/_2]"
      >
        <!-- Billing -->
        <DropdownLink
          :title="$t('customer.sub_nav.billing.btn')"
          :toggle-icon="show.billing"
          @click="toggleDropdown('billing')"
        />
        <div
          :class="{ 'max-h-[600px]': show.billing }"
          class="flex flex-col row-[2_/_2] overflow-hidden max-h-0 transition-[max-height] duration-500 ease-[cubic-bezier(0.33,1,0.68,1)] will-change-[max-height]"
        >
          <router-link
            v-for="(link, i) in routes.billing"
            :key="link.title + i"
            :data-cy="link.route"
            class="!text-etBlack-80 py-2 px-2.5 rounded break-words no-underline hover:!text-etBlue-primary [&.router-link-active]:!text-etBlue-primary [&.router-link-active]:bg-[#f3f6fa]"
            :to="{ name: link.route }"
          >
            <span>{{ $t(link.title) }}</span>
          </router-link>
        </div>
        <!-- Team -->
        <DropdownLink
          :title="$t('shared.label.team')"
          :toggle-icon="show.team"
          @click="toggleDropdown('team')"
        />
        <div
          :class="{ 'max-h-[600px]': show.team }"
          class="flex flex-col row-[2_/_2] overflow-hidden max-h-0 transition-[max-height] duration-500 ease-[cubic-bezier(0.33,1,0.68,1)] will-change-[max-height]"
        >
          <router-link
            v-for="(link, i) in routes.team"
            :key="link.title + i"
            class="!text-etBlack-80 py-2 px-2.5 rounded break-words no-underline hover:!text-etBlue-primary [&.router-link-active]:!text-etBlue-primary [&.router-link-active]:bg-[#f3f6fa]"
            :to="{ name: link.route }"
          >
            <span>{{ $t(link.title) }}</span>
          </router-link>
        </div>
        <!-- Account -->
        <DropdownLink
          :title="$t('shared.label.account')"
          :toggle-icon="show.account"
          data-cy="dropdown_account"
          @click="toggleDropdown('account')"
        />
        <div
          :class="{ 'max-h-[600px]': show.account }"
          class="flex flex-col row-[2_/_2] overflow-hidden max-h-0 transition-[max-height_500ms_cubic-bezier(0.33,1,0.68,1)] will-change-[max-height]"
        >
          <router-link
            v-for="(link, i) in routes.account"
            :key="link.title + i"
            :data-cy="link.route"
            class="!text-etBlack-80 py-2 px-2.5 rounded break-words no-underline hover:!text-etBlue-primary [&.router-link-active]:!text-etBlue-primary [&.router-link-active]:bg-[#f3f6fa]"
            :to="{ name: link.route }"
          >
            <span>{{ $t(link.title) }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import DropdownLink from '@/components/navbar/DropdownLink'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import i18n from '@/i18n'
import _ from 'underscore'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const route = useRoute()
const router = useRouter()

const show = ref({
  billing: true,
  team: false,
  account: false
})
const routeTitle = ref('')

const routes = computed(() => store.state.navigation.routes)
const baseRoute = computed(() => store.state.navigation.baseRoute)
const showNav = computed(() => store.state.navigation.showNav)
const isSubnavVisible = computed(() => {
  if (baseRoute.value == 'contentGenerationV2') return true
  return showNav.value
})

watch(
  () => route,
  (newRoute) => {
    showInitalDropdown()
    switch (newRoute.meta.baseRoute) {
      case 'clientSettings':
        routeTitle.value = $t('shared.nav.settings.btn')
        break
      case 'languageAssets':
        routeTitle.value = $t('shared.nav.language_assets.btn')
        break
      case 'importLanguageAssets':
        routeTitle.value = $t('shared.nav.language_assets.btn')
        break
      default:
        routeTitle.value = $t('customer.nav.overview.title')
        break
    }
  },
  { deep: true, immediate: true }
)

function toggleDropdown(keyName) {
  if (show.value[keyName]) {
    show.value[keyName] = false
    if (keyName === 'billing') {
      router.push({ name: 'clientPaymentBilling' })
    }
    return
  }
  _.mapObject(show.value, (val, key) => {
    if (key === keyName) {
      show.value[key] = true
      if (keyName === 'billing') {
        router.push({ name: 'clientPaymentBilling' })
      }
    } else {
      show.value[key] = false
    }
  })
}
function showInitalDropdown() {
  _.mapObject(show.value, (val, key) => {
    if (key === route.meta.group) {
      show.value[key] = true
    } else {
      show.value[key] = false
    }
  })
}
</script>
