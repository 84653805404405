<template>
  <div
    class="w-full min-w-max h-max px-4 grid grid-rows-1 gap-x-4 rounded mt-2 bg-white shadow-sm border-2 border-transparent relative items-center hover:border-etBlack-30"
    :class="{
      'py-3 cursor-pointer grid grid-cols-[minmax(150px,_1fr)_150px_150px_minmax(100px,_1fr)_250px]':
        documentsTable,
      'py-2 grid grid-cols-[minmax(100px,_1fr)_150px_minmax(100px,_1fr)_250px]':
        templatesTable
    }"
    :data-cy="`cg-${rowItems.id}`"
    @click="onClick"
  >
    <ModalEditCaasTemplate
      :template="rowItems"
      :show-modal="showModalEditTemplate"
      :templates-modal="templatesTable"
      @close="closeEditModal"
    />

    <ModalDeleteCaasDocument
      :item="rowItems"
      :show-modal="showDeleteCaasDocumentModal"
      :documents-modal="documentsTable"
      :templates-modal="templatesTable"
      @close="closeDelete"
    />
    <ModalRenameCaasDocument
      :show-modal="showRenameCaasDocumentModal"
      :documents-modal="documentsTable"
      :templates-modal="templatesTable"
      :item="rowItems"
      @close="closeRename"
    />
    <TextSmall class="w-[150px]">
      {{ rowItems.attributes.title }}
    </TextSmall>
    <TextSmall class="bg-etBlack-20 py-1 px-2 rounded-sm w-max">
      {{ currentUser.attributes.first_name }}
      {{
        currentUser.attributes.last_name
          ? currentUser.attributes.last_name[0] + '.'
          : ''
      }}
    </TextSmall>
    <TextSmall class="bg-etBlack-20 py-1 px-2 rounded-sm w-max">
      {{ formateDate(rowItems.attributes.created_at) }}
    </TextSmall>
    <TextSmall
      v-if="documentsTable"
      class="bg-etBlack-20 py-1 px-2 rounded-sm w-max"
    >
      {{ rowItems.attributes.raw_content?.split(' ').length || 0 }}
    </TextSmall>
    <div :class="{ 'flex justify-between items-center': templatesTable }">
      <div
        v-if="templatesTable && route.name === 'caasTemplates'"
        :data-cy="`editTemp-${rowItems.id}`"
        class="flex gap-2 items-center border border-etBlack-40 rounded-sm py-2 px-3 cursor-pointer hover:bg-etBlue-20 [&>*]:hover:!text-etBlue-primary hover:border-etBlue-primary"
        @click="handleEditTemplate"
      >
        <font-awesome-icon icon="fa-solid fa-pen" />
        <TextSmall>
          {{ $t('shared.content_generation.edit_template') }}
        </TextSmall>
      </div>
      <slot name="editBtn" />
      <slot name="applyTemplateBtn" />
      <!-- <div>
        <div @click="toggleDownloadMenu">
          <slot name="menuBtn" />
        </div>
        <div v-if="showDownloadMenu" v-click-outside="onClickOutsideDownload">
          <TextSmall @click="toggleCaasDownloadModal">
            Microsoft Word(.docx)
          </TextSmall>
          <TextSmall @click="toggleCaasDownloadModal">
            Microsoft Word(.xls)
          </TextSmall>
          <TextSmall @click="toggleCaasDownloadModal"> JSON(.json) </TextSmall>
        </div>
      </div> -->
      <ButtonEditCard
        :data-cy="`edit-${rowItems.id}`"
        class="!relative ml-auto top-0"
      >
        <template #option>
          <span
            v-if="allowRename"
            data-cy="toggle_rename"
            @click.prevent.stop="handleRenameClick"
            >{{ $t('shared.label.rename') }}</span
          >
          <span
            v-if="allowEditTemplate"
            @click="$emit('editTemplate', rowItems)"
            >{{ $t('shared.label.edit') }}</span
          >
          <span v-if="allowExportTemplate">{{
            $t('shared.label.export')
          }}</span>
          <span
            class="!text-etRed-100"
            data-cy="toggle_delete"
            @click.prevent.stop="handleDeleteClick"
            >{{ $t('shared.button.delete') }}</span
          >
        </template>
      </ButtonEditCard>
    </div>
  </div>
</template>
<script setup>
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TextSmall from '@/components/shared/font/text/TextSmall'
import ModalDeleteCaasDocument from '@/components/shared/modals/ModalDeleteCaasDocument'
import ModalEditCaasTemplate from '@/components/shared/modals/ModalEditCaasTemplate'
import ModalRenameCaasDocument from '@/components/shared/modals/ModalRenameCaasDocument'
import i18n from '@/i18n'
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const router = useRouter()
const route = useRoute()

const showDeleteCaasDocumentModal = ref(false)
const showRenameCaasDocumentModal = ref(false)
const showModalEditTemplate = ref(false)

const emit = defineEmits(['editTemplate'])

const props = defineProps({
  rowItems: Object,
  documentsTable: {
    type: Boolean,
    default: false
  },
  templatesTable: {
    type: Boolean,
    default: false
  },
  allowRename: {
    type: Boolean,
    default: false
  },
  allowExportTemplate: {
    type: Boolean,
    default: false
  },
  allowEditTemplate: {
    type: Boolean,
    default: false
  }
})

const currentUser = computed(() => store.state.workspace.currentUser)

const stopLoader = () => store.dispatch('loading/stopLoader')
const getDocument = (payload) =>
  store.dispatch('contentGeneration/getDocument', payload)

onMounted(() => {
  stopLoader()
})

function handleRenameClick() {
  showRenameCaasDocumentModal.value = true
}
function closeRename() {
  showRenameCaasDocumentModal.value = false
}
function handleDeleteClick() {
  showDeleteCaasDocumentModal.value = true
}
function handleEditTemplate() {
  showModalEditTemplate.value = true
}
function closeEditModal() {
  showModalEditTemplate.value = false
}
function closeDelete() {
  showDeleteCaasDocumentModal.value = false
}
async function onClick() {
  if (props.documentsTable) {
    await getDocument(props.rowItems?.id)
    router.push({
      name: 'contentGenerationV2'
    })
  }
}
function formateDate(date) {
  if (!date) return '-'
  return moment(date).format('DD.MM.YYYY')
}
</script>
