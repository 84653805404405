<template>
  <div
    class="header h-[100px] desktop:h-auto w-full p-5 desktop:pt-2.5 desktop:pr-0 desktop:pb-8 desktop:pl-0 fixed desktop:relative top-0 left-0 z-[3] flex flex-col items-center justify-end bg-white desktop:bg-transparent transition-all duration-300 ease-in-out"
  >
    <Banner v-if="enableUpgrade && baseRoute != 'clientSettings'" />
    <slot />
    <HeaderMobile class="header__inner--mobile" />
  </div>
</template>
<script setup>
import Banner from '@/components/banner/Banner'
import HeaderMobile from '@/components/header/HeaderMobile'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const baseRoute = computed(() => store.state.navigation.baseRoute)
const enableUpgrade = computed(() => store.getters['account/enableUpgrade'])
</script>
