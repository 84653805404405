<template>
  <div
    v-if="accounts.length > 0 && showAccounts"
    class="flex-col hidden bg-white p-0 rounded shadow overflow-y-scroll select-none"
    :class="{ '!block': showAccounts }"
    @mouseleave="emit('setShowAccounts', false)"
  >
    <div
      v-for="account in accounts"
      :key="account.attributes.team_identifier"
      class="py-4 px-6 border-b border-etBlack-10 hover:bg-etBlue-10"
      @click="handleAccountSwitch(account.attributes.team_identifier)"
    >
      <TextRegular class="cursor-pointer" bold>
        <p>{{ capitalizeFirstLetter(account.attributes.company_name) }}</p>
      </TextRegular>
      <TextSmall
        class="cursor-pointer !text-etBlue-80 underline desktop:no-underline desktop:hover:underline"
      >
        {{ account.attributes.team_identifier }}
      </TextSmall>
    </div>
  </div>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextSmall from '@/components/shared/font/text/TextSmall'
import { capitalizeFirstLetter } from '@/helpers/format'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['setShowAccounts'])
const props = defineProps({
  showAccounts: Boolean
})

const currentUserAccounts = computed(
  () => store.state.workspace.currentUserAccounts
)
const accounts = computed(() => {
  if (currentUserAccounts.value.length > 0) {
    return currentUserAccounts.value.filter(
      (account) =>
        account.attributes.team_identifier !== route.params.teamIdentifier
    )
  } else {
    return []
  }
})

function handleAccountSwitch(team) {
  router.push({
    name: 'clientOverview',
    params: { teamIdentifier: team }
  })
}
</script>
