<template>
  <div
    v-if="currentUser.attributes"
    class="hidden rounded gap-2 flex-col absolute bottom-[10px] left-[70px] bg-white p-4 shadow-lg w-[300px] select-none"
    :class="{
      '!flex': show,
      '!left-[260px]': expanded && !isSidebar,
      'relative flex flex-col bg-white !left-0 !bottom-0 min-w-250px shadow-none w-full !px-2':
        isSidebar,
      'border border-etBlack-30': !isSidebar
    }"
  >
    <div class="flex flex-col rounded p-2">
      <TextRegular bold>
        {{
          `${currentUser.attributes.first_name} ${
            currentUser.attributes.last_name || ''
          }`
        }}
      </TextRegular>
      <TextTiny>
        {{ currentUser.attributes.email }}
      </TextTiny>
    </div>
    <hr v-if="isNotSupplier" class="w-full bg-etBlack-30" />
    <div
      v-if="isNotSupplier || isAdmin"
      class="flex items-center gap-3 p-3 rounded hover:bg-etBlue-10"
      :class="{ 'cursor-pointer': currentUserAccounts.length > 1 }"
      @click.prevent="isMobile && emit('setShowAccounts', !showAccounts)"
      @mouseenter="!isMobile && emit('setShowAccounts', true)"
    >
      <InlineSvg
        :src="require('@/assets/icons/navbar/default/accounts.svg')"
        alt="dot"
        width="20"
      />
      <div class="w-full flex items-center justify-between">
        <div>
          <TextRegular semi-bold v-if="isNotSupplier">
            {{ capitalizeFirstLetter(currentAccountId) }}
          </TextRegular>
          <TextSmall v-if="isAdmin" blue @click="handleSwitchAccount">
            <font-awesome-icon icon="fa-arrows-rotate" />
            {{ $t('shared.button.switch_account') }}
          </TextSmall>
        </div>
        <font-awesome-icon
          v-if="currentUserAccounts.length > 1"
          class="text-etBlack-100"
          :class="{ '!rotate-90': showAccounts }"
          icon="fa-solid fa-chevron-right"
        />
      </div>
    </div>
    <div class="border-b border-etBlack-30"></div>
    <div v-if="!isSidebar">
      <router-link
        class="flex items-center gap-3 p-2 rounded hover:bg-etBlue-30"
        :to="{
          name: isNotSupplier ? 'news' : 'supplierNews'
        }"
      >
        <InlineSvg
          :src="
            baseRoute === 'news' || baseRoute === 'supplierNews'
              ? require('@/assets/icons/navbar/active/news.svg')
              : require('@/assets/icons/navbar/default/news.svg')
          "
          alt="dot"
          width="20"
        />
        <TextRegular semi-bold>
          {{ $t('shared.nav.news.btn') }}
        </TextRegular>
      </router-link>
    </div>
    <!-- g2 review -->
    <div v-if="!isSidebar" @click="toggleG2ReviewModal">
      <div class="flex items-center gap-3 p-2 rounded hover:bg-etBlue-30">
        <InlineSvg
          :src="
            require('@/assets/icons/navbar/default/rectangle-list-regular.svg')
          "
          alt="dot"
          width="20"
          fill="#767c98"
        />
        <TextRegular semi-bold>G2 review</TextRegular>
      </div>
    </div>
    <div v-if="!isSidebar" data-cy="account_settings">
      <router-link
        class="flex items-center gap-3 p-2 rounded hover:bg-etBlue-30"
        :to="{ name: settingBaseRoute }"
      >
        <InlineSvg
          :src="
            baseRoute === 'clientSettings' || baseRoute === 'supplierSettings'
              ? require('@/assets/icons/navbar/active/settings.svg')
              : require('@/assets/icons/navbar/default/settings.svg')
          "
          alt="dot"
          width="20"
        />
        <TextRegular semi-bold>
          {{ $t('shared.nav.settings.btn') }}
        </TextRegular>
      </router-link>
    </div>
    <div
      class="flex items-center gap-3 p-2 rounded text-etRed-70 hover:bg-etRed-10 hover:text-etRed-100"
      @click="handleLogout"
    >
      <InlineSvg
        :src="require('@/assets/icons/navbar/default/logout.svg')"
        alt="dot"
        width="20"
      />
      <TextRegular semi-bold class="text-etRed-100">
        {{ $t('shared.nav.logout.btn') }}
      </TextRegular>
    </div>
  </div>
</template>
<script setup>
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import { capitalizeFirstLetter } from '@/helpers/format'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const router = useRouter()

const emit = defineEmits(['setShowAccounts', 'logout'])
const props = defineProps({
  show: Boolean,
  showAccounts: Boolean,
  isSidebar: {
    type: Boolean,
    default: false
  },
  expanded: {
    type: Boolean,
    default: true
  }
})
const isMobile = ref(false)

const currentUser = computed(() => store.state.workspace.currentUser)
const currentUserAccounts = computed(
  () => store.state.workspace.currentUserAccounts
)
const baseRoute = computed(() => store.state.navigation.baseRoute)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)
const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const hasSandbox = computed(() => store.getters['account/hasSandbox'])
const isNotSupplier = computed(() => $cookies.get('userType') !== 'supplier')
const settingBaseRoute = computed(() => {
  if (isNotSupplier.value) {
    if (hasSandbox.value) return 'clientPriceLimit'
    return 'clientPaymentBilling'
  }
  return 'supplierSettings'
})

onMounted(() => {
  window.addEventListener('touchstart', (e) => {
    isMobile.value = true
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('touchstart', (e) => {
    isMobile.value = true
  })
})

const toggleG2ReviewModal = () => store.commit('modals/toggleG2ReviewModal')

function handleLogout() {
  emit('logout')
}
function handleSwitchAccount() {
  const resetConfig = {
    self: false,
    modules: {}
  }
  Object.keys(store._modules.root._children).forEach((moduleKey) => {
    if (moduleKey != 'workspace') {
      resetConfig.modules[moduleKey] = {
        self: true,
        nested: true
      }
    }
  })
  store.reset(resetConfig)
  router.push({ name: 'accountsList' })
}
</script>
