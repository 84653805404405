<template>
  <div
    class="header__inner w-full !hidden desktop:!grid items-center z-[1] grid-cols-[1fr_auto] grid-rows-[1fr_1fr] mobileLandscape:grid-rows-[1fr] desktop:grid-cols-[1fr_auto_auto_auto_auto]"
    :class="{
      'header__inner--list desktop:grid-cols-[1fr_auto_auto_auto_auto_auto]':
        showViewBtn,
      'header__inner--buttons': showCreateProjectBtn
    }"
  >
    <Breadcrumbs />
    <div
      v-if="showViewBtn"
      class="header__inner--item ml-5 rounded-md overflow-hidden"
    >
      <button
        class="p-2.5 cursor-pointer text-base bg-white border-none text-etBlack-50"
        :class="{ '!text-etBlack-80 !bg-etGray-primary': !showListView }"
        data-cy="grid_view"
        @click="onSetListView(false)"
      >
        <i class="fas fa-grip-horizontal" />
      </button>
      <button
        class="p-2.5 cursor-pointer text-base bg-white border-none text-etBlack-50"
        :class="{ '!text-etBlack-80 !bg-etGray-primary': showListView }"
        data-cy="list_view"
        @click="onSetListView(true)"
      >
        <i class="fas fa-list" />
      </button>
    </div>
    <slot />
    <BtnPrimary
      v-if="onTranslatorView"
      class="header__inner--item ml-5"
      :title="$t('shared.label.add_translators')"
      @click="router.push({ name: 'clientTeamStepOne' })"
    />
    <div
      v-if="showCreateProjectBtn"
      class="header__inner__column header__inner--item ml-5 relative"
    >
      <button
        v-if="account?.attributes?.boolean_flags !== undefined"
        class="flex items-center bg-etBlue-primary text-white border-none rounded-sm py-2.5 px-5 shadow-sm hover:bg-etBlue-100 hover:text-white"
        @click="showModal"
      >
        <font-awesome-icon class="button__icon" icon="fa-plus-square" />
        <span class="block pl-1.5">{{
          $t('shared.button.create_project')
        }}</span>
      </button>
    </div>
    <BtnSecondary
      v-if="showCreateProjectBtn"
      class="header__inner--item ml-5 px-5 bg-white border border-etBlue-primary shadow-sm hover:bg-white hover:no-underline"
      :title="$t('shared.button.create_folder')"
      @click="toggleCreateFolderModal"
    />
    <!-- <IconMessages
      is-customer
      class="header__inner--item header__inner--item--messages mr-4"
    /> -->
  </div>
</template>
<script setup>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore()
const $t = i18n.t

const currentSubscriptionTierSupplierTeam = computed(() => {
  return account.value.attributes.current_subscription_tier.supplier_team.tier
})
const currentSubscriptionTierSupplierTeamUsage = computed(() => {
  return account.value.attributes.current_subscription_tier.supplier_team.usage
})

const maxFreelancersForTier = computed(() => {
  const allTiersSupplier = allTiers.value.attributes.freemium.supplier_team
  const max = allTiersSupplier.find((plan) => {
    return plan.tier == currentSubscriptionTierSupplierTeam.value
  })
  return max.maximum
})

const baseRoute = computed(() => store.state.navigation.baseRoute)
const showListView = computed(() => store.state.account.showListView)
const account = computed(() => store.state.account.account)
const allTiers = computed(() => store.state.account.allTiers)
const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const freemiumUsage = computed(() => store.getters['account/freemiumUsage'])
const showViewBtn = computed(() => {
  return [
    'clientAutomationFlows',
    'clientOverview',
    'clientFolders',
    'clientProjects',
    'clientFolder'
  ].includes(route.name)
})
const onTranslatorView = computed(() => {
  if (freemiumUsage.value || isAdmin.value) {
    return (
      baseRoute.value === 'clientTranslators' &&
      ![
        'clientTeamStepOne',
        'clientTeamStepTwo',
        'clientTeamStepThree',
        'clientTeamStepFour',
        'clientTeamStepFive',
        'clientTeamStepCopywriting',
        'clientTeamStepCopywritingConfirm'
      ].includes(route.name)
    )
  }
  return false
})

const showCreateProjectBtn = computed(() => {
  return baseRoute.value === 'clientOverview' && !isAdmin.value
})

const setListView = (payload) => store.commit('account/setListView', payload)
const toggleProjectModal = () => store.commit('modals/toggleProjectModal')
const toggleCreateFolderModal = () =>
  store.commit('modals/toggleCreateFolderModal')

const error = (payload) => store.dispatch('toast/error', payload)
function onSetListView(show) {
  $cookies.set('showListView', show)
  setListView(show)
}
async function showModal() {
  toggleProjectModal()
}
function addTranslators() {
  if (
    currentSubscriptionTierSupplierTeamUsage.value >=
      maxFreelancersForTier.value &&
    maxFreelancersForTier.value != -1
  ) {
    error({ message: $t('shared.toast.label.freelancer_limit') })
    return
  } else router.push({ name: 'clientTeamStepOne' })
}
</script>
