<template>
  <div
    v-if="selectedTeamUser.attributes"
    class="m-auto min-h-[65vh] w-full flex flex-col justify-between"
  >
    <div
      v-if="loading"
      class="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.151)] z-[100]"
    >
      <LocalLoader :is-x-large="true" :loading="loading" />
    </div>
    <div v-if="showMethods" class="flex flex-col gap-3">
      <TextSmall :bold="true">
        {{ $t('customer.voucher.added_cards') }}
      </TextSmall>
      <div
        class="flex flex-col gap-2 pr-2 w-full max-h-[400px] overflow-y-auto"
      >
        <LocalLoader :is-medium="true" :loading="loadingMethod" />
        <PaymentCard
          v-for="(method, i) in billing.attributes.methods"
          :key="i"
          :method="method"
          :disabled="!userHasAccess"
          :set-default="true"
          hide-actions
          class="border border-etGray-primary px-4 hover:border-etBlue-primary"
          @setLoader="onMethodLoad"
        />
      </div>
    </div>
    <div v-if="!showMethods" class="flex flex-col gap-6">
      <div class="flex flex-col gap-3">
        <TextSmall :bold="true">
          {{ $t('shared.account.profile_information') }}
        </TextSmall>
        <div
          id="billing-form-name-group"
          class="flex gap-4 flex-col justify-between mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <InputField
            id="billing-form-name-group-first-name"
            class="w-full"
            :placeholder="$t('shared.label.first_name')"
            :required="true"
            :value="user.first_name"
            @inputChange="firstName"
          />
          <InputField
            id="billing-form-name-group-last-name"
            class="w-full"
            :placeholder="$t('shared.label.last_name')"
            :required="true"
            :value="user.last_name"
            @inputChange="lastName"
          />
        </div>
        <div id="billing-form-phone-group">
          <InputFieldPhone
            id="billing-form-phone-group-phone"
            class="w-full"
            :required="false"
            :placeholder="$t('shared.label.phone_number')"
            :value="user.phone"
            @validate="phoneValidation"
            @inputChange="phoneNumer"
          />
        </div>
      </div>
      <div id="billing-form-info" class="flex flex-col gap-3">
        <TextSmall :bold="true">
          {{ $t('shared.account.account_information') }}
        </TextSmall>
        <div
          id="billing-form-info-group"
          class="flex gap-4 flex-col justify-between mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <InputField
            id="billing-form-info-group-company-name"
            class="w-full"
            :placeholder="$t('shared.label.company_name')"
            :required="true"
            :value="company.company_name"
            @inputChange="companyName"
          />
          <InputFieldPhone
            id="billing-form-info-group-company-phone"
            class="w-full"
            :required="false"
            :placeholder="$t('shared.label.company_phone')"
            :value="company.company_phone"
            @validate="companyPhoneValidation"
            @inputChange="companyPhoneNumer"
          />
        </div>
        <div
          id="billing-form-invoice-group"
          class="flex gap-4 flex-col justify-between mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <Select
            id="billing-form-invoice-group-invoice-language"
            class="w-full"
          >
            <SelectSelection
              data-cy="billing-form-invoice-group-invoice-language"
              :value="langValue"
              @inputChange="invoiceLanguage"
            >
              <SelectOption disabled :value="$t('shared.label.language')">
                <span>{{ $t('shared.label.language') }}</span>
              </SelectOption>
              <SelectOption
                v-for="language in languages"
                :key="language.code"
                :value="language.code"
              >
                <span>{{ $t(`shared.languages.${language.code}`) }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
          <Select
            id="billing-form-invoice-group-invoice-timezone"
            class="w-full"
          >
            <SelectSelection
              data-cy="billing-form-invoice-group-invoice-timezone"
              :value="timezoneValue"
              @inputChange="onTimezoneChange"
            >
              <SelectOption disabled :value="$t('shared.label.timezone')">
                <span>{{ $t('shared.label.timezone') }}</span>
              </SelectOption>
              <SelectOption
                v-for="timezone in timezones"
                :key="timezone"
                :value="timezone"
              >
                <span>{{ timezone }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
        </div>
      </div>
      <div id="billing-form-payment" class="flex flex-col gap-3">
        <TextSmall :bold="true">
          {{ $t('customer.voucher.paymet_info') }}
        </TextSmall>
        <div id="billing-form-payment-group">
          <InputFieldEmail
            id="billing-form-payment-group-receipt-email"
            class="w-full"
            :value="billingCompare.attributes.receipt_email"
            key-name="receipt_email"
            :placeholder="$t('shared.label.email')"
            :disabled="!userHasAccess"
            @inputChange="handleEmail"
          />
        </div>
        <div
          id="billing-form-address-group"
          class="flex gap-4 flex-col justify-between mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <InputField
            id="billing-form-address-group-address"
            :required="true"
            :value="billingCompare.attributes.address"
            class="w-full"
            type="text"
            :placeholder="$t('shared.label.billing_address')"
            :disabled="!userHasAccess"
            @inputChange="handleAddress"
          />
          <InputField
            id="billing-form-address-group-postcode"
            :required="true"
            :value="billingCompare.attributes.postcode"
            class="w-full mobileLandscape:!w-[30%]"
            :placeholder="$t('shared.label.postal_code')"
            :disabled="!userHasAccess"
            @inputChange="handlePostalCode"
          />
        </div>
        <div
          id="billing-form-address-2-group"
          class="flex gap-4 flex-col justify-between mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <InputField
            id="billing-form-address-2-group-city"
            :required="true"
            :value="billingCompare.attributes.city"
            class="w-full"
            type="text"
            :placeholder="$t('shared.label.city')"
            :disabled="!userHasAccess"
            @inputChange="handleCity"
          />
          <Select id="billing-form-address-2-group-country" class="w-full">
            <SelectSelection
              data-cy="billing-form-address-2-group-country"
              :value="country"
              @inputChange="handleCountry"
            >
              <SelectOption :disabled="true" :selected="true" value="Country">
                <span>{{ $t('shared.label.country') }}</span>
              </SelectOption>
              <SelectOption
                v-for="c in countries"
                :key="c.code"
                :disabled="!userHasAccess"
                :value="c.code"
              >
                <span>{{ c.name }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
        </div>
        <div
          id="billing-form-other-group"
          class="flex gap-4 flex-col justify-between mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <InputField
            id="billing-form-other-group-vat"
            :required="true"
            :value="billingCompare.attributes.vat_number"
            class="w-full"
            type="text"
            :placeholder="vat"
            :disabled="!userHasAccess || !billingCompare.attributes.country"
            @inputChange="debounceVat"
            @blur="updateVat(billingCompare.attributes.vat_number)"
          />
          <InputField
            id="billing-form-other-vat"
            :required="true"
            class="w-full mobileLandscape:!w-[30%]"
            :value="billingCompare.attributes.currency"
            :disabled="true"
          />
        </div>
        <div id="billing-form-method-group">
          <Select id="billing-form-method-group-method" class="w-full">
            <SelectSelection
              data-cy="billing-form-method-group-method"
              :value="setMethod"
              @inputChange="handleMethod"
            >
              <SelectOption disabled value="placeholder">
                <span>{{ $t('customer.billing.methods.payments') }}</span>
              </SelectOption>
              <SelectOption value="card" selected>
                <span>{{ $t('shared.label.card') }}</span>
              </SelectOption>
              <SelectOption value="sepa_debit">
                <span>SEPA</span>
              </SelectOption>
            </SelectSelection>
          </Select>
        </div>
        <div
          v-if="billing.attributes.methods.length > 0"
          id="billing-form-card-group"
          class="flex flex-col gap-2 w-full max-h-[150px] overflow-y-auto"
        >
          <PaymentCard
            v-for="(method, i) in methods"
            :key="i"
            data-cy="payment_method"
            :method="method"
            :set-default="false"
            hide-actions
            class="w-full cursor-pointer border border-etGray-primary px-4 hover:border-etBlue-primary"
            @click="handleShowMethods()"
          />
        </div>
        <div
          v-if="!billing.attributes.methods.length && selectedMethod"
          id="billing-form-voucher-group"
        >
          <PaymentMethodVoucher
            ref="paymentMethod"
            :disabled="!userHasAccess"
            :picked-method="selectedMethod"
            class="w-full cursor-pointer"
            @stripe="getStripe"
          />
        </div>
        <TextSmall
          data-cy="missing_access_info"
          v-if="!userHasAccess && userList.length > 0"
          class="w-full text-etRed-100 bg-etRed-10 border-l-3 border-etRed-100 p-4"
        >
          {{
            $t('customer.voucher.no_permissions', {
              admin: `${getTeamAdminName}`
            })
          }}
        </TextSmall>
      </div>
      <div
        v-if="
          content == 'upgradeSub' &&
          subscription[1] == 'annually' &&
          subscription[0].plan == 'startup'
        "
        class="flex flex-col gap-3"
      >
        <TextSmall :bold="true">
          {{ $t('customer.updatesub.coupon.title') }}
        </TextSmall>
        <div
          class="flex gap-4 flex-col mobileLandscape:flex-row mobileLandscape:w-full"
        >
          <InputField
            id="coupon_input"
            class="w-full"
            :value="coupon"
            :placeholder="$t('customer.updatesub.coupon.placeholder')"
            @inputChange="handleCoupon"
          />
          <BtnPrimary
            data-cy="coupon_button"
            :title="$t('customer.updatesub.coupon.button')"
            class="min-w-max"
            :disabled="
              !coupon ||
              (coupon != 'BLACKFRIDAY100' && coupon != 'BLACKFRIDAYEXTRA100')
            "
            @click="applyCoupon"
          />
        </div>
      </div>
    </div>
    <BtnsWrapperVertical id="payment-info-button-group">
      <TextTiny v-if="!showMethods && content == 'exceededVoucher'">
        {{ $t('customer.voucher.saving_info') }}
      </TextTiny>
      <BtnPrimary
        v-if="!showMethods"
        id="payment-info-button-group-approve"
        class="w-full"
        :title="$t('shared.button.approve')"
        :disabled="disableButton || !userHasAccess"
        @click="approvePayment(false)"
      />
      <BtnPrimary
        v-if="showMethods"
        id="payment-info-button-group-save"
        class="w-full"
        :title="$t('shared.button.save')"
        @click="handleShowMethods()"
      />
      <BtnSecondary
        id="payment-info-button-group-cancel"
        :title="
          content == 'exceededVoucher' || 'updateBilling'
            ? $t('shared.button.cancel')
            : $t('shared.button.go_back')
        "
        type="button"
        @click="handleCancel"
      />
    </BtnsWrapperVertical>
  </div>
</template>
<script setup>
import PaymentCard from '@/components/payment/PaymentCard'
import PaymentMethodVoucher from '@/components/payment/PaymentMethodVoucher'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import InputField from '@/components/shared/input/InputField'
import InputFieldEmail from '@/components/shared/input/InputFieldEmail'
import InputFieldPhone from '@/components/shared/input/InputFieldPhone'
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import Select from '@/components/shared/select/Select'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import i18n from '@/i18n'
import langs from '@/utils/languages.json'
import beTimezones from '@/utils/timezones.json'
import _ from 'underscore'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const route = useRoute()
const router = useRouter()

const props = defineProps({
  billingData: Object,
  content: String,
  subscription: {
    type: Object,
    default: null
  }
})
const emit = defineEmits([
  'selectedSub',
  'upgrade',
  'disabledModal',
  'handleMt',
  'close'
])

const billingCompare = ref({ ...props.billingData })
const coupon = ref('')
const appliedCoupon = ref('')
const vat = ref($t('shared.label.vat_number'))
const selectedMethod = ref('')
const existingMethod = ref('')
const defaultMethod = ref('')
const showMethods = ref(false)
const loadingMethod = ref(false)
const loading = ref(false)
const stripe = ref('')
const teamAdmin = ref(null)
const user = ref({
  last_name: '',
  first_name: '',
  country: '',
  phone: '',
  timezone: ''
})
const company = ref({
  company_name: '',
  company_phone: '',
  invoice_language: ''
})
const phoneValidated = ref(false)
const companyPhoneValidated = ref(false)
const isBtnDisabled = ref(false)
const paymentMethod = ref()
const paymentMethodDetails = ref({
  name: '',
  email: ''
})

const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)
const countries = computed(() => store.state.account.countries)
const account = computed(() => store.state.account.account)
const userList = computed(() => store.state.teams.userList)
const selectedTeamUser = computed(() => store.state.teams.selectedTeamUser)
const currentUser = computed(() => store.state.workspace.currentUser)
const billing = computed(() => store.state.payment.billing)
const paymentSecret = computed(() => store.state.payment.paymentSecret)
const currentProject = computed(() => store.state.project.currentProject)
const vatTypes = computed(() => store.state.account.vatTypes)
const pendingPages = computed(() => store.state.contentPillars.pendingPages)
const couponDiscount = computed(() => store.state.subscriptions.couponDiscount)
const languages = computed(() => {
  return Object.values(langs)
})
const timezones = computed(() => {
  return beTimezones
})
const langValue = computed(() => {
  if (company.value.invoice_language) return company.value.invoice_language
  return $t('shared.label.language')
})
const timezoneValue = computed(() => {
  if (user.value.timezone) return user.value.timezone
  return $t('shared.label.timezone')
})
const country = computed(() => {
  return billingCompare.value.attributes.country
    ? billingCompare.value.attributes.country
    : 'Country'
})
const companyData = computed(() => {
  return {
    company_name: account.value.attributes.company_name,
    company_phone: account.value.attributes.phone,
    company_email: account.value.attributes.company_email,
    invoice_language: account.value.attributes.invoice_language
  }
})
const profileData = computed(() => {
  return {
    last_name: currentUser.value.attributes.last_name,
    first_name: currentUser.value.attributes.first_name,
    phone: currentUser.value.attributes.phone,
    timezone: currentUser.value.attributes.timezone
  }
})
const methods = computed(() => {
  let methods = []
  billing.value.attributes.methods.forEach((method) => {
    if (method.type == existingMethod.value) {
      methods.push(method)
    }
    if (method.attributes.is_default) {
      if (method.type === 'billing_method_card') {
        defaultMethod.value = 'card'
      } else {
        defaultMethod.value = 'sepa_debit'
      }
    }
  })
  return methods
})
const getTeamAdminName = computed(() => {
  const teamAdminUser = userList.value.find((user) =>
    user.attributes.access.roles.some(
      (role) => role.attributes.name === 'team_admin'
    )
  )
  if (teamAdminUser) {
    return `${teamAdminUser.attributes.first_name} ${teamAdminUser.attributes.last_name}`
  }
  return ''
})
const setMethod = computed(() => {
  return selectedMethod.value ? selectedMethod.value : 'placeholder'
})
const userHasAccess = computed(() => {
  const accessRoles = [
    'create-billing-method',
    'update-billing-method',
    'update-billing',
    'update-team'
  ]
  for (const ability of accessRoles) {
    if (!selectedTeamUser.value.attributes.access.abilities.includes(ability)) {
      return false
    }
  }
  return true
})

watch(
  () => paymentMethod.value.name,
  (n) => (paymentMethodDetails.value.name = n)
)
watch(
  () => paymentMethod.value.email,
  (e) => (paymentMethodDetails.value.email = e)
)

const disableButton = computed(() => {
  if (
    !selectedMethod.value ||
    !user.value.last_name ||
    !user.value.first_name ||
    !billingCompare.value.attributes.country ||
    !company.value.company_name ||
    !user.value.timezone ||
    !company.value.invoice_language ||
    isBtnDisabled.value
  )
    return true
  if (defaultMethod.value && selectedMethod.value != defaultMethod.value)
    return true
  if (!defaultMethod.value) {
    if (selectedMethod.value == 'card') return !paymentMethodDetails.value.name
    else
      return !(
        paymentMethodDetails.value.name && paymentMethodDetails.value.email
      )
  }
  return false
})

const updateBilling = (payload) =>
  store.dispatch('payment/updateBilling', payload)
const getPaymentSecret = (payload) =>
  store.dispatch('payment/getPaymentSecret', payload)
const createBillingMethod = (payload) =>
  store.dispatch('payment/createBillingMethod', payload)
const getBilling = () => store.dispatch('payment/getBilling')
const getSettings = () => store.dispatch('account/getSettings')
const getCustomerAccount = () => store.dispatch('account/getCustomerAccount')
const updateCustomerAccount = (payload) =>
  store.dispatch('account/updateCustomerAccount', payload)
const acceptPrice = (payload) => store.dispatch('tasks/acceptPrice', payload)
const getStateUserList = () => store.dispatch('teams/getStateUserList')
const updateCustomer = (payload) =>
  store.dispatch('teams/updateCustomer', payload)
const error = (payload) => store.dispatch('toast/error', payload)
const upgradeSubscription = (payload) =>
  store.dispatch('subscriptions/upgradeSubscription', payload)
const toggleExceededVoucherModal = () =>
  store.commit('modals/toggleExceededVoucherModal')
const setCouponDiscount = (payload) =>
  store.commit('subscriptions/setCouponDiscount', payload)
const getStateTeamUser = (payload) =>
  store.dispatch('teams/getStateTeamUser', payload)
const startPage = (payload) =>
  store.dispatch('contentPillars/startPage', payload)
const isTrackedFreemiumUser = computed(
  () => store.getters['workspace/isTrackedFreemiumUser']
)
const isTrackedPaidUser = computed(
  () => store.getters['workspace/isTrackedPaidUser']
)
const reportTrackingEvent = (payload) =>
  store.dispatch('teams/reportTrackingEvent', payload)

onBeforeMount(async () => {
  await setInitialValues()
})

onMounted(async () => {
  setCouponDiscount(0)
  await getStateUserList()
  await getStateTeamUser(currentUser.value.id)
  if (defaultMethod.value) {
    if (defaultMethod.value == 'sepa_debit') {
      selectedMethod.value = 'sepa_debit'
      existingMethod.value = 'billing_method_iban'
    } else {
      selectedMethod.value = 'card'
      existingMethod.value = 'billing_method_card'
    }
  }
})

function setInitialValues() {
  company.value = { ...companyData.value }
  user.value = { ...profileData.value }
}
function lastName(name) {
  user.value.last_name = name.value
}
function firstName(name) {
  user.value.first_name = name.value
}
function companyName(name) {
  company.value.company_name = name.value
}
function phoneNumer(number) {
  user.value.phone = number.value
}
function companyPhoneNumer(number) {
  company.value.company_phone = number.value
}
function phoneValidation(validation) {
  phoneValidated.value = validation
}
function companyPhoneValidation(validation) {
  companyPhoneValidated.value = validation
}
function invoiceLanguage(lang) {
  company.value.invoice_language = lang
}
function onTimezoneChange(timezone) {
  user.value.timezone = timezone
}
async function handleCountry(value) {
  billingCompare.value.attributes.country = value
  vat.value = $t('shared.label.vat_number')
  const currentType = Object.keys(vatTypes.value).find(
    (country) => country.toLocaleLowerCase() === value.toLocaleLowerCase()
  )
  vat.value = Object.values(vatTypes.value[currentType]).join(
    ` ${$t('shared.labels.or')} `
  )
  await updateBilling({ attributes: { country: value } })
}
function handleMethod(value) {
  selectedMethod.value = value
  if (billing.value.attributes.methods.length) {
    if (value == 'card') {
      existingMethod.value = 'billing_method_card'
    } else {
      existingMethod.value = 'billing_method_iban'
    }
  }
  const voucherGroup = document.getElementById('billing-form-voucher-group')
  if (voucherGroup)
    setTimeout(() => {
      voucherGroup.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }, 1000)
}
function handleShowMethods() {
  showMethods.value = !showMethods.value
}
function onMethodLoad(loading) {
  loadingMethod.value = loading
}
function handleEmail(value) {
  billingCompare.value.attributes.receipt_email = value.value
}
function handleCity(value) {
  billingCompare.value.attributes.city = value.value
}
function handlePostalCode(value) {
  billingCompare.value.attributes.postcode = value.value
}

const debounceVat = (value) => _.debounce(handleVat(value), 300)

function handleVat(value) {
  billingCompare.value.attributes.vat_number = value.value
}

async function updateVat(value) {
  await updateBilling({ attributes: { vat_number: value } })
}

function handleAddress(value) {
  billingCompare.value.attributes.address = value.value
}
function getStripe(value) {
  stripe.value = value
}
function handleCancel() {
  setCouponDiscount(0)
  if (props.content == 'exceededVoucher') {
    toggleExceededVoucherModal()
    router.replace({
      query: {
        ...route.query,
        exceededVoucher: false
      }
    })
  } else if (props.content == 'upgradeSub') {
    emit('selectedSub', null)
  } else {
    emit('close')
  }
}

function handleCoupon(value) {
  coupon.value = value.value
}

function applyCoupon() {
  appliedCoupon.value = coupon.value
  const discount = coupon.value.match(/\d+$/)
  if (discount) {
    setCouponDiscount(Number(discount[0]))
  }
}

async function approvePayment() {
  loading.value = true
  isBtnDisabled.value = true
  emit('disabledModal', true)
  const requestData = {
    id: currentUser.value.id,
    oldCustomerData: currentUser.value.attributes,
    newCustomerData: user.value
  }
  await updateCustomer(requestData)
    .then(async () => {
      let billingData = billingCompare.value
      delete billingData.attributes['subscription']
      await updateBilling(billingData)
        .then(async () => {
          const {
            company_name: oldName,
            phone: oldPhone,
            invoice_language: oldInvoice
          } = account.value.attributes
          const { company_name, company_phone, invoice_language } =
            company.value
          let payload = {
            account: {
              ...(company_name !== oldName
                ? { companyName: company_name }
                : {}),
              ...(company_phone !== oldPhone ? { phone: company_phone } : {}),
              ...(invoice_language !== oldInvoice
                ? { invoiceLanguage: invoice_language }
                : {})
            },
            id: billingData.id
          }
          await updateCustomerAccount(payload)
            .then(async () => {
              if (!existingMethod.value) {
                await getPaymentSecret({ methodType: selectedMethod.value })
                if (selectedMethod.value === 'card') {
                  await stripe.value
                    .confirmCardSetup(paymentSecret.value, {
                      payment_method: {
                        card: paymentMethod.value.cardNumber,
                        billing_details: {
                          name: paymentMethod.value.name
                        }
                      }
                    })
                    .then(async (res) => {
                      if (res.error) {
                        loading.value = false
                        isBtnDisabled.value = false
                        error({ message: res.error.message })
                        throw new Error(res.error)
                      }
                      await createBillingMethod({
                        stripeToken: res.setupIntent.payment_method
                      })
                      await finishPayment()
                    })
                } else {
                  await stripe.value
                    .confirmSepaDebitSetup(paymentSecret.value, {
                      payment_method: {
                        sepa_debit: paymentMethod.value.ibanElement,
                        billing_details: {
                          name: paymentMethod.value.name,
                          email: paymentMethod.value.email,
                          address: {
                            line1: billing.value.attributes.address,
                            line2: `${billing.value.attributes.postcode} ${billing.value.attributes.city}`,
                            country: billing.value.attributes.country
                          }
                        }
                      }
                    })
                    .then(async (res) => {
                      if (res.error) {
                        loading.value = false
                        isBtnDisabled.value = false
                        error({ message: res.error.message })
                        throw new Error(res.error)
                      }
                      await createBillingMethod({
                        stripeToken: res.setupIntent.payment_method
                      })
                      await finishPayment()
                    })
                }
              } else {
                await finishPayment()
              }
            })
            .then((res) => {
              if (res.error) {
                error({ message: res.error.message })
                throw new Error(res.error)
              }
            })
        })
        .catch((err) => {
          loading.value = false
          isBtnDisabled.value = false
          throw new Error(err)
        })
    })
    .catch((err) => {
      loading.value = false
      isBtnDisabled.value = false
      throw new Error(err)
    })
}

function pushGA4FunnelEvent(funnelName, attrs) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'funnel',
    funnel_name: funnelName,
    first_name: currentUser.value?.attributes?.first_name || '',
    last_name: currentUser.value?.attributes?.last_name || '',
    phone: currentUser.value?.attributes?.phone || '',
    email: currentUser.value?.attributes?.email || '',
    ...attrs,
    nonInteraction: true
  })
}

async function pushAndTrackFreemium() {
  if (!isTrackedFreemiumUser.value) {
    pushGA4FunnelEvent('3_freemium_active', {
      team_name: currentAccountId.value,
      user_id: currentUser.value?.id,
      projectId: route.query.projectId
    })
    await reportTrackingEvent({ isPaidAction: false })
  }
  if (!isTrackedPaidUser.value) {
    pushGA4FunnelEvent('4_freemium_paid', {
      team_name: currentAccountId.value,
      user_id: currentUser.value?.id,
      projectId: route.query.projectId
    })
    await reportTrackingEvent({ isPaidAction: true })
  }
}

async function finishPayment() {
  emit('disabledModal', true)
  loading.value = true
  isBtnDisabled.value = true
  if (props.content == 'upgradeSub') {
    await upgradeSub()
  } else if (props.content == 'exceededVoucher') {
    if (route.name == 'clientProject')
      await acceptPrice({
        projectId: currentProject.value.id
      })
    pushAndTrackFreemium()
    if (route.name === 'clientClustersOverview' && pendingPages.value) {
      pendingPages.value.map(async (p) => await startPage(p.id))
    }
    toggleExceededVoucherModal()
    router.replace({
      query: {
        ...route.query,
        exceededVoucher: false
      }
    })
    loading.value = false
    isBtnDisabled.value = false
    emit('disabledModal', false)
  } else {
    loading.value = false
    isBtnDisabled.value = false
    emit('close')
    emit('handleMt')
  }
}

async function upgradeSub() {
  loading.value = true
  emit('disabledModal', true)
  isBtnDisabled.value = true
  let period = props.subscription[1]
  if (period == 'annually') {
    period = 'yearly'
  }
  let code = null
  if (appliedCoupon.value && couponDiscount.value) code = appliedCoupon.value
  let payload = {
    plan: props.subscription[0].plan,
    period: period,
    coupon: code
  }
  try {
    await upgradeSubscription(payload)
    await getCustomerAccount()
    await getBilling()
    await getSettings()
  } finally {
    const funnelName =
      billing.value.attributes.subscription.attributes.status === 'trialing'
        ? '5_freemium_trial'
        : '6_subscription_plan'
    pushGA4FunnelEvent(funnelName, {
      team_name: currentAccountId.value,
      user_id: currentUser.value?.id,
      ...payload
    })
    if (route.name == 'clientProject' && route.params.upgradeUsage != 'true') {
      await acceptPrice({
        projectId: currentProject.value.id
      })
      pushAndTrackFreemium()
    }
    if (route.name === 'clientClustersOverview' && pendingPages.value) {
      pendingPages.value.map(async (p) => await startPage(p.id))
    }
    emit('upgrade', true)
    emit('disabledModal', false)
    router.replace({
      query: {
        ...route.query,
        upgradeSubscriptionStart: false,
        upgradeSubscriptionPaymentInfo: false
      }
    })
    loading.value = false
    emit('disabledModal', false)
    isBtnDisabled.value = false
    setCouponDiscount(0)
  }
}
</script>
